import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Sponsors from '../components/Sponsors';

// import logo from '../assets/images/logo.png';
import logo from '../assets/images/logo_mahi.png';
import triSea from '../assets/images/sponsors/tri-sea-stabilizers.png';
import montys from '../assets/images/pic01.jpg';
import sal from '../assets/images/shake-a-leg.jpg'
import pic1 from '../assets/images/pic01_mahi.jpg';
// import mansion_yachts from '../assets/images/mansion_yachts.jpg';
// import pic2 from '../assets/images/pic02.jpg';
import pic2 from '../assets/images/pic02_mahi.jpg';
import pic3 from '../assets/images/pic03.jpg';
// import pic4 from '../assets/images/pic04.jpg';
// import pic5 from '../assets/images/pic05.jpg';
// import pic6 from '../assets/images/pic06.jpg';
// import pic7 from '../assets/images/pic07.jpg';

import config from '../../config';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        {/*<h2 style={{color:`red`, borderBottom: `none`, text: `center`, marginBottom: `-.5em`}}> Postponed Due to unforeseen circumstances</h2>*/}
        {/*<h1 style={{color:`red`}}>New dates and times will be posted shortly!</h1>*/}
        <div className="logo">
          <img className="cco-logo" src={logo} alt="CCO Showdown" />
          <a href="https://www.triseastabilizers.com" target="_blank">
            <img className="tri-logo" src={triSea} alt="TriSea Stabilizers" />
          </a>
        </div>
        <h2>{config.subHeading}</h2>
      </div>
    </section>

    <Sponsors />

    <section id="wrapper">
      <section id="one" className="wrapper spotlight style2">
        <div className="inner">
          <a href="https://www.shakealegmiami.org" target="_blank" className="image">
            <img src={sal} alt="Shake-A-Leg Miami" />
          </a>
          <div className="content">
            <h2 className="major">What, When and Where?</h2>
            <p>
              The Second Annual CCO Showdown - Meat Fish Edition Fishing
              Tournament will be hosted in Coconut Grove! With tons of prize
              money and bragging rights for the center console brand you
              represent on the line, you can't afford to miss it!
              <br />
              <br />
              {/*<strong>Venue:</strong>*/}
              {/*<br />*/}
              {/*Shake-A-Leg Miami*/}
              {/*<br />*/}
              {/*2620 S Bayshore Dr, Miami, FL 33133*/}
              {/*<br />*/}
              <br />
              {/*<strong>Dates:</strong> July 8th-10th, 2021*/}
              <strong>Dates:</strong>
              <br />
              Captain's Meeting: August 25th, 2022 at 6:00pm
              <br />
              Tournament: August 27th, 2022 from 7am - 4pm
              <br />
              Awards Ceremony: August 27th, 2022 6pm - 8pm
              <br />
              <br />
              <strong>Location:</strong>
              <br />
              Shake-A-Leg Miami
              <br />
              2620 S Bayshore Dr, Miami, FL 33133
              <br />
            </p>
            {/*<Link to={'Register'} className="special">*/}
            {/*  Register Now*/}
            {/*</Link>*/}
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic1} alt="Winning Mahi" />
          </a>
          <div className="content">
            <h2 className="major">Entry Fees and Prize Structure </h2>
            <p>
              <strong>
                Entry Fee & Prize Structure:
                <br />- $500 / Boat
              </strong>
              {/*<br />*/}
              {/*-- Includes 6 anglers. Each additional angler is $75*/}
              <br />
              <br />
              {/*<strong>Sailfish Prize Structure:</strong> (Included in Sailfish and Mahi Entry Fees)*/}
              {/*<br />*/}
              {/*-- 1st Place boat- 40% & Trophy*/}
              {/*<br />*/}
              {/*-- 2nd Place boat- 30% & Trophy*/}
              {/*<br />*/}
              {/*-- 3rd Place boat- 20% & Trophy*/}
              {/*<br />*/}
              {/*-- 4th Place boat- 10% & Trophy*/}
              {/*<br />*/}
              {/*<br />*/}
              <strong>Prize Structure:</strong> (Included in Entry Fees)
              <br />
              <strong>
                - (60% of the Overall Pot divided as follows for Top 3 Aggregate
                Mahi:)
              </strong>
              <br />
              -- 1st Place boat- 60% & Trophy
              <br />
              -- 2nd Place boat- 30% & Trophy
              <br />
              -- 3rd Place boat- 10% & Trophy
              <br />
              <br />
              <strong>(10% of the Overall Pot) </strong>-- Largest Mahi
              <br />
              This is 10% of the OVERALL POT for the largest mahi caught on a
              rod and reel!!
              <br />
              <br />
              <strong>(10% of the Overall Pot) </strong>-- Largest Tuna
              <br />
              This is 10% of the OVERALL POT for the largest tuna caught on a
              rod and reel!!
              <br />
              <br />
              <strong>(10% of the Overall Pot) </strong>-- Largest Kingfish
              <br />
              This is 10% of the OVERALL POT for the largest kingfish caught on
              a rod and reel!!
              <br />
              <br />
              <strong>(10% of the Overall Pot) </strong>-- Largest Wahoo
              <br />
              This is 10% of the OVERALL POT for the largest wahoo caught on a
              rod and reel!!
              <br />
              <br />
              {/*<strong>(10% of the Overall Pot) </strong>*/}
              {/*-- Yo-Yo Challenge*/}
              {/*<br />*/}
              {/*You think you have what it takes to catch a monster Mahi on a*/}
              {/*traditional yo-yo?? Come and get it. 10% of the OVERALL POT will*/}
              {/*go to the biggest mahi caught on a yo-yo!*/}
              {/*<br />*/}
              {/*<br />*/}
              {/*<strong>Professional Division</strong> (Included in Entry Fees)*/}
              {/*<br />*/}
              {/*-- 1st Place boat – Trophy*/}
              {/*<br />*/}
              {/*-- 2nd Place boat – Trophy*/}
              {/*<br />*/}
              {/*-- 3rd Place boat – Trophy*/}
              {/*<br />*/}
              {/*<br />*/}
              {/*<strong>Semi-Professional Division</strong> (Included in Entry*/}
              {/*Fees)*/}
              {/*<br />*/}
              {/*-- 1st Place boat – Trophy*/}
              {/*<br />*/}
              {/*-- 2nd Place boat – Trophy*/}
              {/*<br />*/}
              {/*-- 3rd Place boat – Trophy*/}
              {/*<br />*/}
              {/*<br />*/}
              {/*<strong>Additional Awards</strong> (Included in Entry Fees)*/}
              {/*<br />*/}
              {/*-- Junior Angler*/}
              {/*<br />*/}
              {/*-- Top Lady Angler*/}
              {/*<br />*/}
              {/*-- Top Overall Angler*/}
              {/*<br />*/}
              {/*-- Top Captain*/}
              {/*<br />*/}
              {/*<br />*/}
              <strong>Calcuttas</strong> (Additional Prize Options)
              {/*<br />*/}
              {/*-- Showdown 1: $1000 entry – 70%/20%/10% Split*/}
              {/*<br />*/}
              {/*-- Showdown 2: $500 entry – 60%/40% Split*/}
              {/*<br />*/}
              {/*-- Sailfish Slayer: $1000 entry - Winner Take All*/}
              <br />
              -- In The Meat: $250 entry - (Aggregate of 3 Largest
              Fish)
              <br />
              -- Meatsauce: $250 entry - (Single Largest fish)
              <br />
              <br />
              ALL BOATS MUST BE A CENTER CONSOLE TO PARTICIPATE AND FISH THE
              TOURNAMENT
            </p>
            {/*<Link to={'Register'} className="special">*/}
            {/*  Register Now*/}
            {/*</Link>*/}
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style4">
        <div className="inner">
          <a href="/#" className="image">
            <img src={pic3} alt="" />
          </a>
          <div className="content">
            <h2 className="major">The Ultimate Center Console Showdown</h2>
            <p>
              Calling all Center Consoles... Only! Fish among some of the best
              anglers on the best center consoles on the market. No matter how
              big or small, be sure to represent your brand to the fullest!
            </p>
            {/*<Link to={'Register'} className="special">*/}
            {/*  Register Now*/}
            {/*</Link>*/}
          </div>
        </div>
      </section>

      {/*<section id="four" className="wrapper alt style1">*/}
      {/*  <div className="inner">*/}
      {/*    <h2 className="major">Vitae phasellus</h2>*/}
      {/*    <p>*/}
      {/*      Cras mattis ante fermentum, malesuada neque vitae, eleifend erat.*/}
      {/*      Phasellus non pulvinar erat. Fusce tincidunt, nisl eget mattis*/}
      {/*      egestas, purus ipsum consequat orci, sit amet lobortis lorem lacus*/}
      {/*      in tellus. Sed ac elementum arcu. Quisque placerat auctor laoreet.*/}
      {/*    </p>*/}
      {/*    <section className="features">*/}
      {/*      <article>*/}
      {/*        <a href="/#" className="image">*/}
      {/*          <img src={pic4} alt="" />*/}
      {/*        </a>*/}
      {/*        <h3 className="major">Sed feugiat lorem</h3>*/}
      {/*        <p>*/}
      {/*          Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id*/}
      {/*          nulla dignissim dapibus ultrices.*/}
      {/*        </p>*/}
      {/*        <a href="/#" className="special">*/}
      {/*          Learn more*/}
      {/*        </a>*/}
      {/*      </article>*/}
      {/*      <article>*/}
      {/*        <a href="/#" className="image">*/}
      {/*          <img src={pic5} alt="" />*/}
      {/*        </a>*/}
      {/*        <h3 className="major">Nisl placerat</h3>*/}
      {/*        <p>*/}
      {/*          Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id*/}
      {/*          nulla dignissim dapibus ultrices.*/}
      {/*        </p>*/}
      {/*        <a href="/#" className="special">*/}
      {/*          Learn more*/}
      {/*        </a>*/}
      {/*      </article>*/}
      {/*      <article>*/}
      {/*        <a href="/#" className="image">*/}
      {/*          <img src={pic6} alt="" />*/}
      {/*        </a>*/}
      {/*        <h3 className="major">Ante fermentum</h3>*/}
      {/*        <p>*/}
      {/*          Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id*/}
      {/*          nulla dignissim dapibus ultrices.*/}
      {/*        </p>*/}
      {/*        <a href="/#" className="special">*/}
      {/*          Learn more*/}
      {/*        </a>*/}
      {/*      </article>*/}
      {/*      <article>*/}
      {/*        <a href="/#" className="image">*/}
      {/*          <img src={pic7} alt="" />*/}
      {/*        </a>*/}
      {/*        <h3 className="major">Fusce consequat</h3>*/}
      {/*        <p>*/}
      {/*          Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id*/}
      {/*          nulla dignissim dapibus ultrices.*/}
      {/*        </p>*/}
      {/*        <a href="/#" className="special">*/}
      {/*          Learn more*/}
      {/*        </a>*/}
      {/*      </article>*/}
      {/*    </section>*/}
      {/*    <ul className="actions">*/}
      {/*      <li>*/}
      {/*        <a href="/#" className="button">*/}
      {/*          Browse All*/}
      {/*        </a>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*</section>*/}
    </section>
  </Layout>
);

export default IndexPage;
