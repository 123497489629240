import React from 'react';

import dc from '../assets/images/sponsors/dcfuel.png';
// import ec from '../assets/images/sponsors/elcapitan.png';
import openFisherman from '../assets/images/sponsors/openfisherman.png';
// import gt from '../assets/images/sponsors/gtechniq.png';
import mf from '../assets/images/sponsors/marinefasteners.png';
// import edson from '../assets/images/sponsors/edson.png';
import clarion from '../assets/images/sponsors/clarion.png';
// import siren from '../assets/images/sponsors/siren.png';
// import plated from '../assets/images/sponsors/plated_perfection.png';
// import frigate from '../assets/images/sponsors/frigate_rum.png';
import mercury from '../assets/images/sponsors/mercury.png';
// import oceanAuto from '../assets/images/sponsors/ocean_auto.png';
import floyd from '../assets/images/sponsors/floyd_fabrication.png';
import ccoConcierge from '../assets/images/sponsors/cco_concierge.png'
import ccoCharters from '../assets/images/sponsors/cco_charters.png'
import lomelos from '../assets/images/sponsors/lomelos.png';
// import hmy from '../assets/images/sponsors/hmy.png'
import allSports from '../assets/images/sponsors/all_sports.png'
import hammer from '../assets/images/sponsors/hammer.png'
import nasco from '../assets/images/sponsors/nasco_worldwide.png'
import orion from '../assets/images/sponsors/orion.png'
import limitless from '../assets/images/sponsors/limitless.png'
import glarb from '../assets/images/sponsors/glarb.png'
import jl from '../assets/images/sponsors/jlaudio.png'
import simrad from '../assets/images/sponsors/simrad.png'
import dna from '../assets/images/sponsors/dna.png'
import teaser from '../assets/images/sponsors/teaser.png'
import wor from '../assets/images/sponsors/wraps_on_right.png'

const sponsors = [
  {
    img: clarion,
    url: 'https://www.clarionmarine.com',
    alt: 'Clarion Marine Audio',
    size: '3',
  },
  {
    img: nasco,
    url: 'https://nascoworldwide.com',
    alt: 'Nasco Worldwide',
    size: '3',
  },
  {
    img: simrad,
    url: 'https://simrad-yachting.com',
    alt: 'SIMRAD Yachting',
    size: '3',
  },
  {
    img: jl,
    url: 'https://www.jlaudio.com/collections/marine-audio',
    alt: 'JL Audio Marine',
    size: '3',
  },
  {
    img: dc,
    url: 'https://www.dcfuelsvc.com',
    alt: 'DC Fuel Services',
    size: '2',
  },
  {
    img: allSports,
    url: 'https://www.allsportsproductions.net',
    alt: 'All Sports Productions',
    size: '2',
  },
  {
    img: dna,
    url: 'https://ceramicdna.com',
    alt: 'Ceramic DNA',
    size: '2',
  },
  {
    img: teaser,
    url: 'https://www.instagram.com/teaser_outfitters/',
    alt: 'Teaser Outfitters',
    size: '2',
  },
  // {
  //   img: edson,
  //   url: 'https://edsonmarine.com',
  //   alt: 'Edson Marine',
  //   size: '3',
  // },
  // {
  //   img: gt,
  //   url: 'https://gtechniq.com/product-category/marine',
  //   alt: 'GTECHNIQ Marine',
  //   size: '3',
  // },
  // {
  //   img: ec,
  //   url: 'https://elcapitan.com',
  //   alt: 'El Capitan Marine and Fishing Center',
  //   size: '2',
  // },
  // {
  //   img: plated,
  //   url: 'https://www.platedperfection.com',
  //   alt: 'Plated Perfection',
  //   size: '2',
  // },

  // {
  //   img: frigate,
  //   url: 'https://frigatereserverum.com',
  //   alt: 'Frigate Rum',
  //   size: '2',
  // },
  {
    img: mf,
    url: 'https://www.marfas.com',
    alt: 'Marine Fasteners',
    size: '2',
  },
  {
    img: hammer,
    url: 'https://hammeryachts.com',
    alt: 'Hammer Yachts',
    size: '2',
  },
  {
    img: orion,
    url: 'https://orionboatworks.com',
    alt: 'Orion Boatworks',
    size: '2',
  },
  {
    img: limitless,
    url: 'https://limitlessboats.com',
    alt: 'Limitless Boats',
    size: '2',
  },
  {
    img: glarb,
    url: 'https://www.instagram.com/glarb.design',
    alt: 'Glarb Designs',
    size: '2',
  },
  {
    img: wor,
    url: 'https://wrapsonright.com',
    alt: 'Wraps On Right',
    size: '2',
  },
  // {
  //   img: mercury,
  //   url: 'https://mercurymarine.com',
  //   alt: 'Mercury Marine',
  //   size: '2',
  // },
  // {
  //   img: oceanAuto,
  //   url: 'https://www.oceanautoclub.com',
  //   alt: 'Ocean Auto Club',
  //   size: '2',
  // },
  {
    img: floyd,
    url: 'https://www.floydfabrication.com',
    alt: 'Floyd Fabrication',
    size: '2',
  },
  // {
  //   img: siren,
  //   url: 'https://sirenmarine.com',
  //   alt: 'Siren Marine  - The Connected Boat',
  //   size: '2',
  // },
  {
    img: openFisherman,
    url: 'https://openfisherman.com',
    alt: 'OpenFisherman.com',
    size: '2',
  },
  {
    img: ccoConcierge,
    url: 'https://centerconsolesonly.net',
    alt: 'CCO Concierge - Mobile Services',
    size: '2',
  },
  {
    img: ccoCharters,
    url: 'https://centerconsolesonly.net',
    alt: 'CCO Charters',
    size: '2',
  },
  // {
  //   img: lomelos,
  //   url: 'https://lomelos.com',
  //   alt: "Lomelo's Steak",
  //   size: '2',
  // },
  // {
  //   img: hmy,
  //   url: 'https://www.hmy.com/outboard-boats-for-sale',
  //   alt: "HMY Outboard Boating Center",
  //   size: '2',
  // },
];

const Sponsors = () => (
  <section id="sponsors" className="wrapper alt spotlight logos style1">
    <div className="inner">
      <div className="box alt align-center">
        <h2>SPONSORED BY:</h2>
        <div
          className="row justify"
          style={{ justifyContent: 'center', margin: 'auto' }}
        >
          {sponsors.map((sponsor, key) => (
            <div key={key} className={'col-' + sponsor.size + ' col-6-small'}>
              <a href={sponsor.url} target="_blank">
                <img
                  style={{ width: '100%' }}
                  src={sponsor.img}
                  alt={sponsor.alt}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default Sponsors;
